import {FC} from "react";

import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";

export const CertifiedCheck: FC = () => {
    return <CheckboxGroup name="certifiedCheckRequired">
        <FieldsRow>
            <TextField name="certifiedCheckNote" />
        </FieldsRow>
    </CheckboxGroup>
}
