interface CheckoutResult {
    id: string;
    url: string;
}

interface GoPayApi {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    checkout(options: {gatewayUrl: string, inline?: boolean}, callback: Function): Promise<CheckoutResult>;
}


declare global {
    interface Window {
        _gopay?: GoPayApi;
    }
}

let goPayApiPromise: Promise<GoPayApi>;

export const loadGoPayApi = (goPayEmbedUrl: string): Promise<GoPayApi> => {
    if (!goPayApiPromise) {
        goPayApiPromise = new Promise((resolve, reject) => {
            if (window._gopay) {
                return resolve(window._gopay);
            }

            const script = document.createElement('script');
            script.src = goPayEmbedUrl;
            script.onerror = reject;
            script.onload = () => {
                resolve(window._gopay!)
            };

            document.head.appendChild(script);
        });
    }

    return goPayApiPromise;
};

export const checkout  = async (goPayEmbedUrl: string, gatewayUrl: string): Promise<CheckoutResult> => {
    const goPayApi = await loadGoPayApi(goPayEmbedUrl);

    return new Promise<CheckoutResult>((resolve) => {
        goPayApi.checkout({gatewayUrl, inline: true}, resolve);
    });
}
