import {FC, useCallback, useEffect, useState} from "react";
import {FormProvider} from "react-hook-form";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";

import {Button} from "@app/common/components/Button/Button";
import {Loading} from "@app/common/components/Loading/Loading";
import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {EditingContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/contexts/EditingContext";
import {usePatchForm} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/usePatchForm";
import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {CertifiedCheck} from "@app/TaxReturnDetail/TaxReturnEdit/PayModal/CertifiedCheck/CertifiedCheck";
import {OnMyBehalf} from "@app/TaxReturnDetail/TaxReturnEdit/PayModal/OnMyBehalf/OnMyBehalf";
import {usePay} from "@app/TaxReturnDetail/TaxReturnEdit/PayModal/paymentService/usePay";
import {PrintingRequired} from "@app/TaxReturnDetail/TaxReturnEdit/PayModal/PrintingRequired/PrintingRequired";
import {useTotalPrice} from "@app/TaxReturnDetail/TaxReturnEdit/PayModal/useTotalPrice";

import './payModal.scss';

export type PayModalProps = {
    taxReturn: TaxReturn;
}

export const PayModal: FC<PayModalProps> = ({taxReturn}) => {
    const {t} = useTranslation();
    const [opened, setOpened] = useState<boolean>(false);

    const [totalPrice, refreshPrice, refreshing] = useTotalPrice(taxReturn.id);
    const pay = usePay();

    const showErrorToast = useShowErrorToast();
    const onPay = useCallback(async () => {
        try {
            await pay(taxReturn.id);
        } catch (error: unknown) {
            showErrorToast(error as Error);
        }
    }, [pay, showErrorToast, taxReturn.id])

    const {form, onSubmit} = usePatchForm(
        taxReturn,
        TaxReturnStep.PAY,
        true,
        refreshPrice,
    );

    useEffect(() => {
        if (opened) {
            form.setFocus('discountCode');
        }
    }, [form, opened]);

    return <div className="pay-modal">
        <Button variant="success" className="pay-modal__pay" onClick={() => setOpened(true)}>
            {t('PayModal.actions.startPayment')}
        </Button>
        <Modal
            isOpen={opened}
            onRequestClose={() => setOpened(false)}
            className="pay-modal__content"
            overlayClassName="pay-modal__overlay"
            ariaHideApp={false}
        >
            <h3>{t('PayModal.header')}</h3>
            {totalPrice >= 0 && <div className="pay-modal__payment">
                <EditingContext.Provider value={true}>
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)}>
                            <FieldsRow>
                                <TextField name="discountCode" />
                            </FieldsRow>
                            <CertifiedCheck />
                            <PrintingRequired />
                            <OnMyBehalf />
                        </form>
                    </FormProvider>
                </EditingContext.Provider>

                <Loading active={refreshing}>
                    <h3>{t('PayModal.totalPrice', {totalPrice})}</h3>
                </Loading>
                <Button variant="success" onClick={onPay}>
                    {t('PayModal.actions.confirmPayment')}
                </Button>
            </div>}
            <div className="pay-modal__cancel">
                <Button variant="secondary" onClick={() => setOpened(false)}>
                    {t('PayModal.actions.cancel')}
                </Button>
            </div>
        </Modal>
    </div>

}
