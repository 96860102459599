import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {Button} from "@app/common/components/Button/Button";
import {Loading} from "@app/common/components/Loading/Loading";
import {FCWithChildren} from "@app/common/model/FCWithChildren";
import {DownloadDocument, useDownload} from "@app/TaxReturnDetail/TaxReturnDownload/hooks/useDownload";

import './downloadButton.scss';

export type DownloadButtonProps = {
    taxReturnId: string;
    document: DownloadDocument;
    fileName: string;
}

export const DownloadButton: FCWithChildren<DownloadButtonProps> = ({children, taxReturnId, document, fileName}) => {
    const [download, downloading] = useDownload(taxReturnId, document, fileName);

    return <Loading active={downloading} className="download-button">
        <Button variant="primary" onClick={download}>
            <FontAwesomeIcon icon={faDownload} />{' '}
            {children}
        </Button>
    </Loading>
}
