import {FC, Fragment, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

import {Loading} from "@app/common/components/Loading/Loading";
import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {ValidatedTaxReturn} from "@app/common/model/ValidatedTaxReturn";
import {FormFieldsContext} from "@app/TaxReturnDetail/TaxReturnEdit/common/contexts/FormFieldsContext";
import {useFormFieldsApi} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormFieldsApi";
import {useValidateTaxReturnApi} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useValidateTaxReturnApi";
import {FormFieldsResponse} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/FormFieldsResponse";
import {TaxReturnStep} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnStep";
import {taxReturnSectionTo} from "@app/TaxReturnDetail/TaxReturnEdit/common/utils/taxReturnSectionTo";
import {stepForValidationTarget} from "@app/TaxReturnDetail/TaxReturnEdit/common/utils/validationTargetForStep";
import {DeductiblesEdit} from "@app/TaxReturnDetail/TaxReturnEdit/DeductiblesEdit/DeductiblesEdit";
import {IncomeEdit} from "@app/TaxReturnDetail/TaxReturnEdit/IncomeEdit/IncomeEdit";
import {PayModal} from "@app/TaxReturnDetail/TaxReturnEdit/PayModal/PayModal";
import {PersonalDataEdit} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/PersonalDataEdit";
import {TaxEdit} from "@app/TaxReturnDetail/TaxReturnEdit/TaxEdit/TaxEdit";

import './taxReturnEditPage.scss';

export type TaxReturnEditPageProps = {
    validatedTaxReturn: ValidatedTaxReturn;
}

export const TaxReturnEditPage: FC<TaxReturnEditPageProps> = ({validatedTaxReturn}) => {
    const {t} = useTranslation();
    const {step} = useParams<'step'>();
    const [searchParams] = useSearchParams();
    const validate = searchParams.get('validate');
    const [validated, setValidated] = useState<boolean>(false);
    const isValidating = useRef<boolean>(false);

    const [formFields, setFormFields] = useState<FormFieldsResponse|null|false>(null);

    const formFieldsApi = useFormFieldsApi();
    const showErrorToast = useShowErrorToast();

    const isPreview = step === TaxReturnStep.PAY;
    const navigate = useNavigate();

    const validateTaxReturnApi = useValidateTaxReturnApi(validatedTaxReturn.taxReturn.id);
    useEffect(() => {
        if (isPreview && !validated && !isValidating.current) {
            isValidating.current = true;
            validateTaxReturnApi()
                .then((validation) => {
                    if (validation.length > 0) {
                        navigate(taxReturnSectionTo(validatedTaxReturn.taxReturn.id, stepForValidationTarget(validation[0]), true));
                    } else {
                        setValidated(true);
                    }
                })
                .catch((error: unknown) => {
                    showErrorToast(error as Error);
                })
                .finally(() => {
                    isValidating.current = false;
                });
        }
    }, [isPreview, navigate, showErrorToast, validateTaxReturnApi, validated, validatedTaxReturn.taxReturn.id]);

    useEffect(() => {
        if (formFields !== null) {
            return;
        }

        formFieldsApi()
            .then(setFormFields)
            .catch((error: unknown) => {
                showErrorToast(error as Error);
                setFormFields(false);
            });
    }, [formFields, formFieldsApi, showErrorToast]);

    return <Loading active={formFields === null} className="tax-return-edit-page">
        {isPreview && <div className="tax-return-edit-page__preview">
            <h3>{t('TaxReturnEditPage.preview.title')}</h3>
            <p>{t('TaxReturnEditPage.preview.subtitle')}</p>
            {!validated && <Loading active={validated}>&nbsp;</Loading>}
        </div>}
        {(!isPreview || validated) && <Fragment>
            {formFields === false && <Fragment>TODO reload button</Fragment>}
            {formFields && <FormFieldsContext.Provider value={formFields}>
                <IncomeEdit
                    validatedTaxReturn={validatedTaxReturn}
                    opened={step === TaxReturnStep.INCOME}
                    validate={step === TaxReturnStep.INCOME && validate !== null}
                    preview={isPreview}
                />
                <PersonalDataEdit
                    validatedTaxReturn={validatedTaxReturn}
                    opened={step === TaxReturnStep.PERSONAL_DATA}
                    validate={step === TaxReturnStep.PERSONAL_DATA && validate !== null}
                    preview={isPreview}
                />
                <DeductiblesEdit
                    validatedTaxReturn={validatedTaxReturn}
                    opened={step === TaxReturnStep.DEDUCTIBLES}
                    validate={step === TaxReturnStep.DEDUCTIBLES && validate !== null}
                    preview={isPreview}
                />
                <TaxEdit
                    validatedTaxReturn={validatedTaxReturn}
                    opened={step === TaxReturnStep.TAX}
                    validate={step === TaxReturnStep.TAX && validate !== null}
                    preview={isPreview}
                />
                {isPreview && validated && <PayModal taxReturn={validatedTaxReturn.taxReturn} />}
            </FormFieldsContext.Provider>}
        </Fragment>}
    </Loading>
}

export const TaxReturnEditPageTitle = 'TaxReturnEditPage.title';

export const TaxReturnEditPagePath = '/tax-return/:id/edit/:step?';
