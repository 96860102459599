import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {generatePath, useNavigate} from "react-router-dom";

import {useApi} from "@app/common/hooks/useApi";
import {useAppConfig} from "@app/common/hooks/useAppConfig";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {TaxReturnDownloadPagePath} from "@app/TaxReturnDetail/TaxReturnDownload/TaxReturnDownloadPage";
import {checkout} from "@app/TaxReturnDetail/TaxReturnEdit/PayModal/paymentService/paymentService";
import {TaxReturnPaymentInfo} from "@app/TaxReturnDetail/TaxReturnEdit/PayModal/paymentService/TaxReturnPaymentInfo";

export const usePay = () => {
    const {i18n} = useTranslation();
    const {goPayEmbedUrl} = useAppConfig();
    const navigate = useNavigate();

    const api = useApi<TaxReturnPaymentInfo>();

    return useCallback(async (taxReturnId: string) => {
        const {gatewayUrl} = await api(
            {url: `/tax-returns/${taxReturnId}/pay/${i18n.language.split('-').shift()}`, method: HttpMethod.POST},
        );

        if (gatewayUrl) {
            await checkout(goPayEmbedUrl, gatewayUrl);
        } else {
            navigate(generatePath(TaxReturnDownloadPagePath, {id: taxReturnId}));
        }
    }, [api, goPayEmbedUrl, i18n.language, navigate]);
}
