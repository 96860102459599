import {FC} from "react";
import {useTranslation} from "react-i18next";

import {TaxReturn} from "@app/common/model/TaxReturn/TaxReturn";
import {DownloadButton} from "@app/TaxReturnDetail/TaxReturnDownload/components/DownloadButton/DownloadButton";
import {DownloadDocument} from "@app/TaxReturnDetail/TaxReturnDownload/hooks/useDownload";

import './taxReturnDownloadPage.scss';

export type TaxReturnDownloadPageProps = {
    taxReturn: TaxReturn;
}

export const TaxReturnDownloadPage: FC<TaxReturnDownloadPageProps> = ({taxReturn}) => {
    const {t} = useTranslation();

    return <div className="tax-return-download-page">
        <h3 className="tax-return-download-page__warning">
            {t('TaxReturnDownloadPage.warning')}
        </h3>
        <div>
            <h4>{t('TaxReturnDownloadPage.forPrint.title')}</h4>
            <p>{t('TaxReturnDownloadPage.forPrint.subtitle')}</p>

            <div className="tax-return-download-page__downloads">
                <DownloadButton taxReturnId={taxReturn.id} document={DownloadDocument.PDF} fileName={t('TaxReturnDownloadPage.filenames.pdf')}>
                    {t('TaxReturnDownloadPage.action.downloadPdf')}
                </DownloadButton>
                <DownloadButton taxReturnId={taxReturn.id} document={DownloadDocument.SUMMARY_LETTER} fileName={t('TaxReturnDownloadPage.filenames.summaryLetter')}>
                    {t('TaxReturnDownloadPage.action.downloadSummaryLetter')}
                </DownloadButton>
            </div>
        </div>
    </div>
}

export const TaxReturnDownloadPageTitle = 'TaxReturnDownloadPage.title';

export const TaxReturnDownloadPagePath = '/tax-return/:id/download/';
