import fileDownload from 'js-file-download';
import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppConfig} from "@app/common/hooks/useAppConfig";
import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {ApiError} from "@app/common/model/ApiError";
import {isErrorResponse} from "@app/common/model/ErrorResponse";
import {HttpMethod} from "@app/common/model/HttpMethod";

export enum DownloadDocument {
    PDF = 'pdf',
    XML = 'xml',
    SUMMARY_LETTER = 'summary-letter-pdf/{{lang}}',
}

export const useDownload = (taxReturnId: string, document: DownloadDocument, fileName: string) => {
    const {i18n} = useTranslation();
    const {apiUrl} = useAppConfig();
    const [downloading, setDownloading] = useState<boolean>(false);

    const showErrorToast = useShowErrorToast();

    const download = useCallback(async () => {
        setDownloading(true);

        const fetchInit: RequestInit = {
            method: HttpMethod.GET,
            headers: new Headers({'Accept-Language': i18n.language}),
            credentials: 'include',
        };

        let url: string = document;
        if ([DownloadDocument.SUMMARY_LETTER].includes(document)) {
            url = url.replace('{{lang}}', i18n.language.split('-').shift() || 'cs');
        }

        try {
            const response = await fetch(`${apiUrl}/tax-returns/${taxReturnId}/${url}`, fetchInit);

            if (response.ok) {
                const data = await response.blob();
                fileDownload(data, fileName);
            } else {
                const errorResponse = await response.json();
                if (isErrorResponse(errorResponse)) {
                    showErrorToast(new ApiError(response.status, errorResponse));
                }

                showErrorToast(new Error(`${response.status} - ${errorResponse}`));
            }
        } catch (error: unknown) {
            showErrorToast(error as Error);
        }
        finally {
            setDownloading(false);
        }
    }, [apiUrl, document, fileName, i18n.language, showErrorToast, taxReturnId]);

    return [download, downloading] as const;
}
