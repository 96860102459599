import {FC} from "react";

import {CheckboxGroup} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/CheckboxGroup/CheckboxGroup";
import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {AddressEdit} from "@app/TaxReturnDetail/TaxReturnEdit/PersonalDataEdit/AddressEdit/AddressEdit";

export const PrintingRequired: FC = () => {
    return <CheckboxGroup name="printingRequired">
        <TextField name="printingInfo.name" />
        <AddressEdit name="printingInfo.address" />
    </CheckboxGroup>

}
