import {FC} from "react";
import {useTranslation} from "react-i18next";

import {AnonymousLayout} from "@app/common/layouts/AnonymousLayout/AnonymousLayout";

export const RegisterPage: FC = () => {
    const {t} = useTranslation();

    return <AnonymousLayout>
        <h1>{t('Login.title')}</h1>
        <p>TODO</p>
    </AnonymousLayout>

}

export const RegisterPagePath = '/sign-up';
