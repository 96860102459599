import {useCallback} from "react";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {TaxReturnPriceItem} from "@app/TaxReturnDetail/TaxReturnEdit/common/model/TaxReturnPriceItem";

export const useTaxReturnPriceApi = () => {
    const api = useApi<TaxReturnPriceItem[]>();

    return useCallback((taxReturnId: string) => {
        return api(
            {url: `/tax-returns/${taxReturnId}/price`, method: HttpMethod.GET},
        );
    }, [api]);
}
