import {useCallback, useState} from "react";

import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {useTaxReturnPriceApi} from "@app/TaxReturnDetail/TaxReturnEdit/PayModal/useTaxReturnPriceApi";

export const useTotalPrice = (taxReturnId: string) => {

    const [totalPrice, setTotalPrice] = useState<number>(-1);
    const [refreshing, setRefreshing] = useState<boolean>(false);

    const taxReturnPriceApi = useTaxReturnPriceApi();
    const showErrorToast = useShowErrorToast();

    const refreshPrice = useCallback(() => {
        setRefreshing(true);
        taxReturnPriceApi(taxReturnId)
            .then((priceItems) => {
                setTotalPrice(Math.round(
                    priceItems.reduce<number>(
                        (total, priceItem) => total + priceItem.amount, 0
                    ) / 100
                ));
            })
            .catch((error: unknown) => {
                setTotalPrice(-2);
                showErrorToast(error as Error);
            })
            .finally(() => {
                setRefreshing(false);
            });
    }, [showErrorToast, taxReturnId, taxReturnPriceApi]);

    return [totalPrice, refreshPrice, refreshing] as const;
}
