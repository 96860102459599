import {FC, Fragment} from "react";

import {TextField} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/fields/TextField/TextField";
import {FieldsRow} from "@app/TaxReturnDetail/TaxReturnEdit/common/components/FieldsRow/FieldsRow";
import {useFormFieldCb} from "@app/TaxReturnDetail/TaxReturnEdit/common/hooks/useFormFieldCb";

export type AddressEditProps = {
    name: 'lastDayAddress'|'czechResidencyAddress'|'permanentAddress'|'printingInfo.address';
}

export const AddressEdit : FC<AddressEditProps> = ({name}) => {
    const formFieldCb = useFormFieldCb();

    return <Fragment>
        <FieldsRow>
            <TextField name={`${name}.street`} customFormField={formFieldCb('permanentAddress.street')} />
            <TextField name={`${name}.number`} customFormField={formFieldCb('permanentAddress.number')} />
        </FieldsRow>
        <FieldsRow>
            <TextField name={`${name}.city`} customFormField={formFieldCb('permanentAddress.city')} />
            <TextField name={`${name}.zipCode`} customFormField={formFieldCb('permanentAddress.zipCode')} />
        </FieldsRow>
    </Fragment>
}
